import React, { Component } from 'react';
import { Link } from "gatsby";
import "./../style/coolharbour-tech-nav.css";
import { Linkedin } from 'react-feather';
import Mainlogo from "../images/mainlogo.svg";
import Mainflag from "../images/ireland.svg";


class Navbar extends Component {

  constructor(props) {
    super(props);
    this.state = {isToggleOn: true};
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState(state => ({
      isToggleOn: !state.isToggleOn
    }));
  }


  render() {
    return (
      <div>
  <div className="menu-top">
  <div className="topblackbar">
    <div className="container has-text-right">
      <a href="https://www.linkedin.com/company/packlims/?viewAsMember=true" target="_blank"><Linkedin/></a>
    </div>
  </div>
  <div className="container showondesktop">
    <div className="columns is-marginless">
      <div className="column is-paddingless is-one-third">
      <Link to="/">
        <img src={Mainlogo} className="mainlogo" alt="Cool Harbour"/>
        </Link>
      </div>
      <div className="column is-paddingless is-one-third is-offset-one-third">
        <div className="level hundheight has-text-right">
          <div className="innerlevel">
          <address>National Software Centre, Mahon, Cork City, Ireland<img className="svgflag" src={Mainflag} alt="some text"/> </address>
          <p>derek.oherlihy@coolharbor.ie</p>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>
<nav className="navbar" role="navigation" aria-label="main navigation">
  <div className="container">
  <div class="navbar-brand">
  <Link className="navbar-item showonmobile" to="/">
        <img src={Mainlogo} className="mainlogo" alt="Cool Harbour"/>
 </Link>
    <a className="navbar-burger burger" onClick={this.handleClick}>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>

    <div id="coolharbourmenu" className={this.state.isToggleOn ? "navbar-menu" : "navbar-menu is-active"}>
      <div className="navbar-end">
      <Link className="navbar-item showondesktop" to="/">Home</Link>
        <Link className="navbar-item" to="/liveview/">LiveView</Link>
        <Link className="navbar-item" to="/packagingprocess/">Packlims</Link>
        <Link className="navbar-item" to="/data-management-portal/">
          Data Management Portal
        </Link>

        <Link className="navbar-item showonmobile" to="/auditor-aggregator/">
          Auditor Aggregator
        </Link>
         <Link className="navbar-item showonmobile" to="/training-aggregator/">
          Training Aggregator
        </Link>
         <Link className="navbar-item showonmobile" to="/learning-management-system/">
          Learning Management System
        </Link>
        <Link className="navbar-item showonmobile" to="/laboratory-information-management-systems/">
          Laboratory Information Management System
        </Link>
{/* 
        <div className="navbar-item has-dropdown is-hoverable showondesktop">
          <div className="navbar-link">
            More Tools
          </div>
          <div className="navbar-dropdown">
        <Link className="navbar-item" to="/auditor-aggregator/">
          Auditor Aggregator
        </Link>
         <Link className="navbar-item" to="/training-aggregator/">
          Training Aggregator
        </Link>
         <Link className="navbar-item" to="/learning-management-system/">
          Learning Management System
        </Link>
        <Link className="navbar-item" to="/laboratory-information-management-systems/">
          Laboratory Information Management System
        </Link>
          </div>
        </div> */}

        <Link className="navbar-item" to="/data-security/">
         Data Security
        </Link>
        <Link className="navbar-item" to="/about-coolharbour/">
         About
        </Link>
        <Link className="navbar-item" to="/about-coolharbour/#contactform">
         Contact
        </Link>
      </div>
    </div>
  </div>
</nav>
      </div>
    );
  }
}

export default Navbar;